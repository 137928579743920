import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import React from "react";
import StarRating from "./StarRating";

function formatPhoneNumber(phoneNumberString) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

const convertTo12HourTime = (time) => {
  let [hour, minute] = time.split(":");
  let period = "AM";

  if (hour === "00") {
    hour = "12";
  } else if (hour === "12") {
    period = "PM";
  } else if (hour > 12) {
    hour = String(hour - 12);
    period = "PM";
  }

  return `${hour}:${minute} ${period}`;
};

export default function Company({ company, service = "towing" }) {
  const { name, phone, service_hours, rating } = company;

  const checkOpeningStatus = () => {
    const userDay = new Date()
      .toLocaleString("en-US", { weekday: "short" })
      .toLowerCase();
    const currentHour = new Date().toLocaleString("en-US", {
      hour: "2-digit",
      hour12: false,
    });
    const currentTime =
      currentHour + new Date().toLocaleString("en-US", { minute: "2-digit" });
    const matchingDay = service_hours?.find((day) => day.day === userDay);

    if (matchingDay) {
      const { opens, closes } = matchingDay;
      const userTime =
        currentHour +
        new Date().toLocaleString("en-US", {
          minute: "2-digit",
          hour12: false,
        });

      if (
        currentTime >= opens &&
        currentTime <= closes &&
        userTime >= opens &&
        userTime <= closes
      ) {
        return (
          <span className="text-green-600 font-semibold  ">
            Open{" "}
            {(opens === "00:00") & (closes === "24:00") ? (
              <>- 24 Hours</>
            ) : (
              <span className="text-black capitalize">
                {" "}
                - open for {service}
              </span>
            )}
          </span>
        );
      } else {
        return (
          <span className="text-red-400 font-semibold">
            Closed{" "}
            <span className="text-black">
              - Opens: {convertTo12HourTime(service_hours[0].opens)}
            </span>
          </span>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <div className="w-full bg-white border p-5 grid grid-cols-company2 gap-10 rounded-lg">
      <div className="text-left">
        <h2 className="text-lg font-bold lg:text-2xl uppercase">{name}</h2>
        <p className="py-2">Phone: {formatPhoneNumber(phone)}</p>

        {rating && (
          <div className="flex items-center gap-4">
            <div className="flex items-center font-medium gap-1">
              {rating}
              <StarRating rating={rating} />
            </div>
            <div className="p-1 pr-3 rounded-full bg-green-500 gap-1 flex items-center text-white">
              <CheckBadgeIcon className="w-5" /> Verified
            </div>
          </div>
        )}
      </div>
      {service_hours && (
        <div className="flex flex-col items-center lg:items-start">
          <p className="font-semibold text-gray-500 border-b mb-3">Business Hours</p>
          <div>
            {service_hours?.map((hour, index) => (
              <div
                key={index}
                className="text-gray-700 grid grid-cols-hours gap-7 text-sm hover:bg-gray-100 w-fit px-1"
              >
                <p className="capitalize">{hour.day}</p>
                {hour.opens ? (
                  <>
                    {(hour.opens === "00:00") & (hour.closes === "24:00") ? (
                      <>24 Hours</>
                    ) : (
                      <>
                        {convertTo12HourTime(hour.opens)} -{" "}
                        {convertTo12HourTime(hour.closes)}
                      </>
                    )}
                  </>
                ) : (
                  "Closed"
                )}
              </div>
            ))}
          </div>
          {service_hours && (
            <p className="mt-2 ml-1 text-lg">{checkOpeningStatus()}</p>
          )}
        </div>
      )}
    </div>
  );
}
