import React, { useState } from "react";
import { websites } from "../../../components/Menu/Menu";

export default function AmitApis() {
  const [amitSiteId, setAmitSiteId] = useState(1);
  return (
    <div>
      <div className="flex justify-between mt-20 pt-8 border-t">
        <h1 className="text-3xl font-semibold">Amit's apis</h1>
        <div className="flex flex-col w-fit gap-3">
          <p className="font-semibold text-xs">Ids for Amit's api</p>
          <select
            onChange={(e) => setAmitSiteId(e.target.value)}
            className="inputField py-2 cursor-pointer w-fit -mt-2"
          >
            {websites.map((item, index) => (
              <option value={item.id} key={index}>
                {item.id} {item.domain}
              </option>
            ))}
          </select>
        </div>
      </div>
      <p className="font-semibold mt-2">States api: </p>
      <a
        target="_blank"
        rel="noreferrer"
        className="text-gray-600 apiLink"
        href={`https://api15.ecommcube.com/_${amitSiteId}apidata/states?pass=billy`}
      >
        {`https://api15.ecommcube.com/_${amitSiteId}apidata/states?pass=billy`}{" "}
      </a>{" "}
      <p className="font-semibold mt-2">Counties api: </p>
      <a
        target="_blank"
        rel="noreferrer"
        className="text-gray-600 apiLink"
        href={`https://api15.ecommcube.com/_${amitSiteId}apidata/counties?state_name=alabama&pass=billy`}
      >
        {`https://api15.ecommcube.com/_${amitSiteId}apidata/counties?state_name=alabama&pass=billy`}{" "}
      </a>{" "}
      <p className="font-semibold mt-2">Citites api: </p>
      <a
        target="_blank"
        rel="noreferrer"
        className="text-gray-600 apiLink"
        href={`https://api15.ecommcube.com/_${amitSiteId}apidata/cities?county_name=denali%20borough&pass=billy`}
      >
        {`https://api15.ecommcube.com/_${amitSiteId}apidata/cities?county_name=denali%20borough&pass=billy`}{" "}
      </a>{" "}
      <p className="font-semibold mt-2">Zips api: </p>
      <a
        target="_blank"
        rel="noreferrer"
        className="text-gray-600 apiLink"
        href={`https://api15.ecommcube.com/_${amitSiteId}apidata/zips?city_name=autaugaville&pass=billy`}
      >
        {`https://api15.ecommcube.com/_${amitSiteId}apidata/zips?city_name=autaugaville&pass=billy`}{" "}
      </a>{" "}
      <p className="font-semibold mt-2">Search api: </p>
      <a
        target="_blank"
        rel="noreferrer"
        className="text-gray-600 apiLink"
        href={`https://api15.ecommcube.com/_${amitSiteId}apidata/search?_sk=35540`}
      >
        {`https://api15.ecommcube.com/_${amitSiteId}apidata/search?_sk=35540`}{" "}
      </a>{" "}
      <p className="font-semibold mt-2">Tag List api: </p>
      <a
        target="_blank"
        rel="noreferrer"
        className="text-gray-600 apiLink"
        href={`https://api15.ecommcube.com/_${amitSiteId}apidata/taglist?pattern=faqs&pass=billy`}
      >
        {`https://api15.ecommcube.com/_${amitSiteId}apidata/taglist?pattern=faqs&pass=billy`}{" "}
      </a>{" "}
      <p className="font-semibold mt-2">Tag Text Api:</p>
      <a
        target="_blank"
        rel="noreferrer"
        className="text-gray-600 apiLink"
        href={`https://api15.ecommcube.com/_${amitSiteId}apidata/tagtext?tag=service_0&pass=billy`}
      >
        {`https://api15.ecommcube.com/_${amitSiteId}apidata/tagtext?tag=service_0&pass=billy`}{" "}
      </a>{" "}
      <p className="font-semibold mt-2">Dashboard Api for all tags:</p>
      <a
        target="_blank"
        rel="noreferrer"
        className="text-gray-600 apiLink"
        href={`https://api15.ecommcube.com/_${amitSiteId}apidata/jtags?pass=billy`}
      >
        {`https://api15.ecommcube.com/_${amitSiteId}apidata/jtags?pass=billy`}{" "}
      </a>{" "}
    </div>
  );
}
