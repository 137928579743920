import React from "react";
import LayoutHead from "../Layout/LayoutHead";
import { useParams } from "react-router-dom";
import { websites } from "../../components/Menu/Menu";
import { GlobeAltIcon } from "@heroicons/react/24/solid";
import { Breadcrumbs } from "../../components";
import { images } from "./list";

export default function Images() {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);

  return (
    <div>
      <LayoutHead website={site}>
        <Breadcrumbs />
      </LayoutHead>
      <div className="p-5 grid grid-cols-4 gap-3">
        {images.map((item, index) => (
          <div className="bg-white p-3 rounded-md grid grid-cols-2">
            <p className="font-medium capitalize">{item.title}</p>
            <a
              href={`https://api15.ecommcube.com/${site?.domain}/${item.address}`}
              target="_blank"
              key={index}
              className="flex justify-end items-start"
            >
              <img
                src={`https://api15.ecommcube.com/${site?.domain}/${item.address}`}
                className="w-fit"
                alt=""
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
