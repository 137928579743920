import React from "react";
import { tagsType } from "../../../components/Menu/Menu";

export default function SystemTags() {
  return (
    <div>
      <h2 className="text-2xl font-semibold mt-20 pt-8 border-t">
        System Tags
      </h2>
      <table className="min-w-full divide-y divide-gray-200 mt-5">
        <thead className="bg-gray-50 sticky top-0 shadow-sm ">
          <tr>
            <th scope="col" className="tableHead">
              Tag
            </th>
            <th scope="col" colSpan={2} className="tableHead">
              Description
            </th>
          </tr>
        </thead>
        <tbody className="bg-white text-gray-900 divide-y divide-gray-200 h-full overflow-y-scroll">
          {tagsType.map((tag, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="p-3 py-2">
                <div className="text-sm ">{tag.name}</div>
              </td>
              <td colSpan={2} className="p-3 py-2">
                <div className="text-sm">{tag.des}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="mt-3">
        <b>Note:</b>{" "}
        <p className="text-red-500">
          While creating a service tag there should be no extra space or extra
          line in service value. It can create an error while deploying on
          server.
        </p>
        <p className="font-bold">For Example:</p>
        If the tag key is service_15 and value is wheel lift towing. There
        should not be any extra space or extra line.
      </p>
    </div>
  );
}
