import React from "react";

const TextArea = ({
  name,
  label,
  onChange,
  onBlur,
  disabled = false,
  placeholder,
  value,
  error,
  className,
}) => {
  return (
    <div className="w-full">
      {label && (
        <label className="inputLabel mb-1" htmlFor={name}>
          {label}
        </label>
      )}
      <textarea
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        className={`inputField ${className}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <span className="text-red-500">{error}</span>}
    </div>
  );
};

export default TextArea;
