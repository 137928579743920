import React, { useState } from "react";
import { Link } from "react-router-dom";
import { websites } from "../../components/Menu/Menu";
import DashboardNav from "../../components/DashboardNav";
import axios from "axios";
import { toast } from "react-toastify";

export default function Dashboard({ show_navlabel, toggleNavbar }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAllReploying, setIsAllReploying] = useState([]);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredWebsites = websites.filter(
    (site) =>
      site.domain.toLowerCase().includes(searchQuery.toLowerCase()) ||
      site.id.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      site.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleRedeploy = async (domain) => {
    setIsLoading(domain);
    try {
      const res = await axios.post(`https://www.${domain}/api/rebuild`);
      if (res.data.status === 200) {
        toast.success("Domain redeployed successfully");
      } else {
        toast.error("Domain couldn't be redeployed");
      }
    } catch (err) {
      toast.error("Domain couldn't be redeployed");
      console.log("👊 ~ handleRedeploy ~ err:", err);
    }
    setIsLoading(null);
  };
  const handleRedeployAll = async () => {
    const nonExcludedDomains = filteredWebsites
      ?.filter(({ domain, active }) => active && !domain.includes("localhost"))
      .map(({ domain }) => domain);

    // Set only the filtered domains in the state
    setIsAllReploying(nonExcludedDomains);
    for (const site of filteredWebsites || []) {
      if (site.active && site.domain.includes("localhost")) {
        continue;
      }
      await handleRedeploy(site.domain);
      setIsAllReploying((prev) =>
        prev.filter((domain) => domain !== site.domain)
      );
    }
    setIsAllReploying([]);
  };
  return (
    <>
      <DashboardNav
        show_navlabel={show_navlabel}
        toggleNavbar={toggleNavbar}
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
      />
      <div className="p-4 lg:p-6">
        <div className="flex w-full justify-end items-center">
          <button
            disabled={isAllReploying?.length || isLoading}
            onClick={handleRedeployAll}
            className="py-2 px-4 font-medium border rounded-md w-auto mb-2 bg-secondary text-white hover:bg-secondary transition-all"
          >
            Redeploy All
          </button>
        </div>

        <div
          className={`grid md:grid-cols-2 ${
            show_navlabel ? "lg:grid-cols-3" : "lg:grid-cols-4"
          } gap-3 lg:gap-5`}
        >
          {filteredWebsites.map((site, index) => (
            <div key={index} className="card p-5">
              <div className="flex items-center gap-2">
                {/* <GlobeAltIcon className="w-10 opacity-80" /> */}
                <div className="w-10 h-10 rounded-lg border-gray-400 border flex items-center justify-center">
                  <img
                    src={`https://api15.ecommcube.com/${site.domain}/favicon-32x32.png`}
                    className="w-8"
                    alt=""
                  />
                </div>
                <div>
                  <p className="elementHeading5 capitalize font-bold">
                    {site.name}
                  </p>
                  <a
                    href={`https://www.${site.domain}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p className="text-gray-500 -mt-1 hover:text-blue-600 transition-all">{`https://www.${site.domain}`}</p>
                  </a>
                </div>
              </div>

              {/* <div className="grid grid-cols-2 gap-2 mb-2 mt-3 opacity-80 hover:opacity-100">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://github.com/billy-prod/amt-mb-15/tree/${site.domain}`}
                >
                  <button
                    type="button"
                    className="btnWhite shadow-none hover:shadow-md border w-full text-black py-2"
                  >
                    <img src="/github.png" alt="github" className="w-5" />
                    Github
                  </button>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://api15.ecommcube.com/${site.domain}`}
                >
                  <button
                    type="button"
                    className="btnWhite shadow-none hover:shadow-md border w-full text-black py-2"
                  >
                    <PhotoIcon className="w-5" />
                    Images
                  </button>
                </a>
              </div> */}

              <div className="flex items-center gap- mt-4 gap-3">
                <Link to={`/${site.domain}/zips`}>
                  <button className="py-2 px-5 font-medium border rounded-md w-full hover:text-white hover:bg-secondary transition-all">
                    Zips List
                    {/* <span className="bg-black text-white py-1 px-4 rounded-full">
                    300,000
                  </span> */}
                  </button>
                </Link>
                <Link className="flex-1" to={`/${site.domain}`}>
                  <button className="py-2 px-4 font-medium border rounded-md w-full hover:text-white hover:bg-secondary transition-all">
                    Manage Website
                  </button>
                </Link>
              </div>
              {site.active ? (
                <div className="flex w-full justify-center">
                  <button
                    disabled={isAllReploying?.length || isLoading}
                    onClick={() => handleRedeploy(site.domain)}
                    className="py-2 px-4 font-medium border rounded-md w-1/2 mt-2 bg-primary text-white hover:bg-secondary transition-all"
                  >
                    {isLoading && isLoading === site.domain
                      ? "Redeploying..."
                      : isAllReploying?.includes(site.domain)
                      ? "In Queue"
                      : "Redeploy"}
                  </button>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
