import {
  CheckBadgeIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";
import React, { useRef, useState } from "react";
import { InputField, Modal } from "../../components";
import { Link } from "react-router-dom";
import StarRating from "./StarRating";

function capitalizeFirstLetter(text) {
  return text?.charAt(0).toUpperCase() + text?.slice(1);
}

const convertTo12HourTime = (time) => {
  let [hour, minute] = time.split(":");
  let period = "AM";

  if (hour === "00") {
    hour = "12";
  } else if (hour === "12") {
    period = "PM";
  } else if (hour > 12) {
    hour = String(hour - 12);
    period = "PM";
  }

  return `${hour}:${minute} ${period}`;
};

// const hours = [
//   { day: "mon", opens: "10:00", closes: "21:30" },
//   { day: "tue", opens: "00:00", closes: "24:00" },
//   { day: "wed", opens: "10:00", closes: "21:30" },
//   { day: "thu", opens: "10:00", closes: "21:30" },
//   { day: "fri", opens: "10:00", closes: "21:30" },
//   { day: "sat", opens: "00:00", closes: "12:00" },
//   { day: "sun", opens: "", closes: "" },
// ];

export default function Company({
  key,
  service,
  companyName,
  rating,
  location,
  phone,
  city,
  hours,
  county,
  state,
  zip,
  name,
  email,
  className,
  cardLocation,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const checkOpeningStatus = () => {
    const userDay = new Date()
      .toLocaleString("en-US", { weekday: "short" })
      .toLowerCase();
    const currentHour = new Date().toLocaleString("en-US", {
      hour: "2-digit",
      hour12: false,
    });
    const currentTime =
      currentHour + new Date().toLocaleString("en-US", { minute: "2-digit" });
    const matchingDay = hours?.find((day) => day.day === userDay);

    if (matchingDay) {
      const { opens, closes } = matchingDay;
      const userTime =
        currentHour +
        new Date().toLocaleString("en-US", {
          minute: "2-digit",
          hour12: false,
        });

      if (
        currentTime >= opens &&
        currentTime <= closes &&
        userTime >= opens &&
        userTime <= closes
      ) {
        return (
          <span className="text-green-600 font-semibold  ">
            Open{" "}
            {(opens === "00:00") & (closes === "24:00") ? (
              <>- 24 Hours</>
            ) : (
              <span className="text-black capitalize">
                {" "}
                - open for {service}
              </span>
            )}
          </span>
        );
      } else {
        return (
          <span className="text-red-400 font-semibold">
            Closed{" "}
            <span className="text-black">
              - Opens: {convertTo12HourTime(hours[0].opens)}
            </span>
          </span>
        );
      }
    } else {
      return null;
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    zipCode: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        // Handle success, e.g., show a confirmation message
        console.log("Email sent successfully");
      } else {
        // Handle error, e.g., show an error message
        console.error("Email could not be sent");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <div
      key={key}
      className={
        cardLocation !== "addCompany"
          ? `w-full bg-white border p-5 lg:p-6 mb-5 grid lg:grid-cols-company gap-5 lg:gap-10 backdrop-blur-sm shadow-md rounded-lg`
          : `w-full bg-white border p-5 grid gap-2 backdrop-blur-sm shadow-md rounded-lg`
      }
    >
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        handleModalSubmit=""
        className="max-w-2xl"
        title={`Contact ${companyName}`}
        modalType="newKey"
        showConfirmation={showConfirmation}
      >
        {showConfirmation ? (
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl">Data added successfully!</p>
            <button className="btnPrimary mt-5" onClick={handleModal}>
              Ok
            </button>
          </div>
        ) : (
          <div className="w-full">
            <div className="grid lg:grid-cols-2 gap-4">
              <InputField
                label="name"
                name="name"
                placeholder="Enter full name"
                required={true}
                value={formData.name}
                onChange={handleChange}
              />
              <InputField
                label="email"
                name="email"
                placeholder="Enter your email"
                type="email"
                required={true}
                value={formData.email}
                onChange={handleChange}
              />
              <InputField
                label="phone"
                name="phone"
                placeholder="Phone number"
                required={true}
                value={formData.phone}
                onChange={handleChange}
              />
              <InputField
                label="Zip Code"
                name="zipCode"
                placeholder="Service Location"
                required={true}
                value={formData.zipCode}
                onChange={handleChange}
              />
            </div>
            <label htmlFor="message" className="inputLabel mt-4">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              placeholder="Explain the type of towing service you need.."
              className="inputField mt-1 h-32"
              required
              value={formData.message}
              onChange={handleChange}
            />
            <div className="mt-4">
              <button
                type="button"
                onClick={handleSubmit}
                className="btnPrimary rounded-md py-3 text-2xl"
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </Modal>
      <div className="text-left">
        <h2 className="text-lg font-bold lg:text-2xl uppercase">
          {companyName || "Company Name"}
        </h2>
        <p className="text-base font-medium capitalize text-gray-500 my-1">
          <span className="mr-2 font-bold">Serving:</span>
          {`${city || "City Name"}, ${zip || "Zip Code"}`}
        </p>
        {rating && (
          <div className="flex items-center gap-4">
            <div className="flex items-center font-medium gap-1">
              {rating}
              <StarRating rating={rating} />
            </div>
            <div className="p-1 pr-3 rounded-full bg-green-500 gap-1 flex items-center text-white">
              <CheckBadgeIcon className="w-5" /> Verified
            </div>
          </div>
        )}
        <span className="text-gray-500 grid grid-cols-location capitalize mt-3">
          <MapPinIcon className="w-5" />
          {location || "Company Current Address"}
        </span>
        {state && (
          <p className="mt-2">
            Serving the city of {capitalizeFirstLetter(city)}, and the
            surrounding areas with coverage extended to{" "}
            {capitalizeFirstLetter(county)} county and throughout the state of{" "}
            {capitalizeFirstLetter(state)} area.
          </p>
        )}
      </div>
      {hours && (
        <div className="flex flex-col items-center lg:items-start">
          <h5 className="elementHeading2 m-0 mb-1 ml-1">Business Hours</h5>
          <div>
            {hours?.map((hour, index) => (
              <div
                key={index}
                className="text-gray-700 grid grid-cols-hours gap-7 text-sm hover:bg-gray-100 w-fit px-1"
              >
                <p className="capitalize">{hour.day}</p>
                {hour.opens ? (
                  <>
                    {(hour.opens === "00:00") & (hour.closes === "24:00") ? (
                      <>24 Hours</>
                    ) : (
                      <>
                        {convertTo12HourTime(hour.opens)} -{" "}
                        {convertTo12HourTime(hour.closes)}
                      </>
                    )}
                  </>
                ) : (
                  "Closed"
                )}
              </div>
            ))}
          </div>
          {hours && <p className="mt-2 ml-1 text-lg">{checkOpeningStatus()}</p>}
        </div>
      )}
      <div className="">
        {cardLocation !== "addCompany" && (
          <p className="text-3xl font-bold text-gray-700">Call Now:</p>
        )}
        <div className="w-full flex flex-col text-center mt-3">
          {cardLocation !== "addCompany" ? (
            <Link
              className={`bg-blue-500 btnPrimary text-2xl font-bold py-3 flex items-center justify-center`}
              to={`tel:${phone}`}
            >
              <PhoneIcon className="w-6 mr-3" />
              <button>{phone || "(000) 000000"}</button>
            </Link>
          ) : (
            <Link to={`tel:${phone}`} className="flex items-center">
              <PhoneIcon className="w-4 mr-2" />
              <button>{phone || "(000) 000000"}</button>
            </Link>
          )}{" "}
          {cardLocation === "addCompany" && (
            <div className="w-full text-left">
              <p className="mt-1">
                Name: <b>{name}</b>
              </p>
              <p>
                Email: <b> {email}</b>
              </p>
            </div>
          )}
          {hours && (
            <>
              <div className="w-full text-lg py-1">or</div>
              <button
                className="btnPrimary rounded-md py-3 text-2xl"
                onClick={handleModal}
              >
                Request a quote
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
