import React from "react";
import Intro from "./Intro";
import MainFeatures from "./MainFeatures";
import CreateUpdateApi from "./CreateUpdateApi";
import SystemTags from "./SystemTags";
import ServicePageGuide from "./ServicePageGuide";
import BlogsTags from "./BlogsTags";
import AmitApis from "./AmitApis";
import VishnuApis from "./VishnuApis";

export default function DocsBody() {
  return (
    <div>
      <Intro />
      <MainFeatures />
      <CreateUpdateApi />
      <SystemTags />
      <ServicePageGuide />
      <BlogsTags />
      <AmitApis />
      <VishnuApis />
      Single Tag api
      https://api15.ecommcube.com/_1apidata/tagtext?tag=service_0&pass=billy
      <br />
      Zip Search api https://api15.ecommcube.com/_1apidata/search?_sk=35540
    </div>
  );
}
