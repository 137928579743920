import React from "react";

const features = [
  {
    name: "Tags Table",
    description:
      "A data table based on each tag used to show data on websites.  Tags Table allows user to add edit and delete national websites tags invidually.",
  },
  {
    name: "Deployment",
    description:
      "User can redploy each website using the redeploy button on top right corner of each website detail page.",
  },
  {
    name: "Robots.txt",
    description: "Robots.txt file can be edited using the dashboard.",
  },
  {
    name: "Sitemap",
    description: "A button to update sitemap on single click.",
  },
  {
    name: "Sync",
    description:
      "Sync button is used to synchronoize data on dashboard with MySql database that is being used as backup for this dashboard",
  },
  {
    name: "Blogs",
    description: "On blogs page users can read add edit and delete blogs.",
  },
];
export default function MainFeatures() {
  return (
    <div>
      <h2 className="text-2xl font-semibold mt-10 border-t pt-8">
        Main Features
      </h2>
      <p className="mt-2 text-gray-600">
        Some of the main National Sites Admin panel features include:
      </p>

      <div className="w-full">
        <table className="min-w-full divide-y divide-gray-200 mt-5">
          <thead className="border-b-2 border-gray-300">
            <tr>
              <th scope="col" className="tableHead">
                Feature
              </th>
              <th scope="col" className="tableHead text-left" colSpan={2}>
                Description
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 h-full overflow-x-scroll text-gray-600">
            {features?.map((item, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="py-2 px-3 flex items-start">
                  <div className="text-sm whitespace-nowrap">{item.name}</div>
                </td>
                <td className="py-2 px-3" colSpan={2}>
                  <div className="text-sm">{item.description}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
