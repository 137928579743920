import React, { useState } from "react";
import { vishnuIds } from "../../../components/Menu/Menu";

export default function VishnuApis() {
  const [vishnuSiteId, setVishnuSiteId] = useState(1);

  const handleSyncApi = () => {
    const confirmed = window.confirm(
      "This link will sync data with database.  Please confirm to continue"
    );
    if (confirmed) {
      const url = `http://15.204.60.73:5555/sync?pass=billy&_id=${vishnuSiteId}`;
      window.open(url, "_blank");
    }
  };

  const handleSitemapApi = () => {
    const confirmed = window.confirm(
      `This link will regenerate site map for website with id = ${vishnuSiteId}.  Please confrim to continue.`
    );
    if (confirmed) {
      const url = `http://15.204.60.73:5555/sitemap?pass=billy&_id=${vishnuSiteId}`;
      window.open(url, "_blank");
    }
  };

  const handleDeployApi = () => {
    const confirmed = window.confirm(
      `This link will redeploy the website with id = ${vishnuSiteId}.  Please confrim to continue`
    );
    if (confirmed) {
      const url = `http://15.204.60.73:5555/deploy?pass=billy&_id=${vishnuSiteId}`;
      window.open(url, "_blank");
    }
  };
  return (
    <div className="my-20">
      <div className="flex justify-between pt-8 border-t">
        <h1 className="text-3xl font-semibold">Vishnu's apis</h1>
        <div className="flex flex-col w-fit gap-3">
          <p className="font-semibold text-xs">Ids for Vishnu's apis</p>
          <select
            onChange={(e) => setVishnuSiteId(e.target.value)}
            className="inputField py-2 cursor-pointer w-fit -mt-2"
          >
            {vishnuIds.map((item, index) => (
              <option value={item.id} key={index}>
                {item.id} {item.domain}
              </option>
            ))}
          </select>
        </div>
      </div>
      <p className="font-semibold mt-2">Companies api: </p>
      <a
        target="_blank"
        rel="noreferrer"
        className="text-gray-600 apiLink"
        href={`https://deployapi.ecommcube.com/api/custom_vendors/search_vendor/35404`}
      >
        {`https://deployapi.ecommcube.com/api/custom_vendors/search_vendor/35404`}{" "}
      </a>{" "}
      <p className="font-semibold mt-2">Sync Api </p>
      <button
        className="text-gray-600 apiLink"
        onClick={handleSyncApi}
      >{`http://15.204.60.73:5555/sync?pass=billy&_id=${vishnuSiteId}`}</button>
      <p className="font-semibold mt-2">Sitemap Api </p>
      <button
        className="text-gray-600 apiLink"
        onClick={handleSitemapApi}
      >{`http://15.204.60.73:5555/sitemap?pass=billy&_id=${vishnuSiteId}`}</button>
      <p className="font-semibold mt-2">Deploy Api </p>
      <button
        className="text-gray-600 apiLink"
        onClick={handleDeployApi}
      >{`http://15.204.60.73:5555/deploy?pass=billy&_id=${vishnuSiteId}`}</button>
    </div>
  );
}
