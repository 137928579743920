import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { websites } from "../../components/Menu/Menu";
import { InputField } from "../../components";
import axios from "axios";
import { toast } from "react-toastify";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import LayoutHead from "../Layout/LayoutHead";
import { Bars3BottomLeftIcon, GlobeAltIcon } from "@heroicons/react/24/outline";

export default function HowTo({ show_navlabel, toggleNavbar }) {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);
  const [newKey, setNewKey] = useState("howto_steps");
  const [newValue, setNewValue] = useState("");
  const [updateKey, setUpdateKey] = useState("");
  const [updateValue, setUpdateValue] = useState("");
  const [howtoTitle, setHowtoTitle] = useState("");
  const [howtoDescription, setHowtoDescription] = useState("");
  const [editTitle, setEditTitle] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [howToSteps, setHowToSteps] = useState([]);
  const [newStep, setNewStep] = useState(false);

  const getHowToTitle = () => {
    const tagName = "howToComponentTitle";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setHowtoTitle(res.data.text))
      .catch((err) => console.log(err));
  };

  const getHowToDescription = () => {
    const tagName = "howToComponentDescription";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setHowtoDescription(res.data.text))
      .catch((err) => console.log(err));
  };

  const getHowToSteps = () => {
    const tagName = "howto_steps";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setHowToSteps(JSON.parse(res.data.text)))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getHowToTitle();
    getHowToDescription();
    getHowToSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Inside your component
  const [editIndex, setEditIndex] = useState(null);
  const [editedValue, setEditedValue] = useState("");

  const editHowToSteps = (index) => {
    setEditIndex(index);
    setEditedValue(howToSteps[index]);
  };

  const handleSaveEditHowToSteps = (index) => {
    const updatedSteps = [...howToSteps];
    updatedSteps[index] = editedValue;
    setHowToSteps(updatedSteps);
    setEditIndex(null);
    setEditedValue("");
  };

  const addHowToStep = () => {
    const updatedHowToSteps = [...howToSteps, newValue];
    setHowToSteps(updatedHowToSteps);
    setNewValue("");
  };

  const deleteHowToSteps = (indexToDelete) => {
    const updatedSteps = howToSteps.filter(
      (_, index) => index !== indexToDelete
    );
    setHowToSteps(updatedSteps);
  };

  const SubmitStep = async (event) => {
    event.preventDefault();

    try {
      await axios.post(
        `https://api15.ecommcube.com/_${site.id}apidata/jtags_update`,
        null,
        {
          params: {
            howto_steps: JSON.stringify(howToSteps),
            pass: "billy",
          },
        }
      );

      toast.success("How To Steps Updated");
      getHowToSteps();
      setHowToSteps([]);
    } catch (err) {
      toast.error(err);
    }
  };

  const handleUpdateKey = (key, value) => {
    setUpdateKey(key);
    setUpdateValue(value);
  };
  const handleUpdateValue = () => {
    axios
      .post(
        `https://api15.ecommcube.com/_${site.id}apidata/jtags_update`,
        null,
        {
          params: {
            [updateKey]: updateValue,
            pass: "billy",
          },
        }
      )
      .then(() => toast.success("Value updated successfully"))
      .catch((err) => toast.error(err));
    setUpdateKey("");
    setUpdateValue("");
  };

  return (
    <>
      <LayoutHead>
        <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              {" "}
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}

          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              href={`https://www.${site?.domain}`}
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
              rel="noreferrer"
            >
              <GlobeAltIcon className="text-gray-700 w-6 mt-1" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>

      <div className="p-6">
        <div className="flex items-center justify-between">
          <h1 className="elementHeading2">How To Steps</h1>
          <div className="flex items-center justify-end gap-3">
            {newStep ? (
              <div className="flex items-center gap-2">
                <button
                  onClick={SubmitStep}
                  className="btnPrimary bg-green-600"
                >
                  Save & Update
                </button>
                <button
                  onClick={() => setNewStep(false)}
                  className="btnPrimary bg-red-500"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button onClick={() => setNewStep(true)} className="btnPrimary">
                Add New Step
              </button>
            )}
          </div>
        </div>
        {newStep && (
          <div className="flex items-end gap-5 mb-5 bg-white p-6 rounded-lg mt-6">
            <div className="w-32">
              <InputField
                label="Key Name"
                placeholder="Enter key tag"
                value={newKey}
                onChange={(event) => setNewKey(event.target.value)}
                disabled
              />
            </div>
            <div className="flex-1 items-end gap-3">
              <InputField
                label="Write New Step"
                placeholder="Enter New Step Here and press add"
                value={newValue}
                onChange={(event) => setNewValue(event.target.value)}
              />
            </div>
            <button
              type="button"
              onClick={addHowToStep}
              className="btnPrimary py-3"
            >
              Add
            </button>
          </div>
        )}
        <div className="bg-white p-5 rounded-lg mt-6">
          <div>
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-2">
                {editTitle ? (
                  <button
                    onClick={() => {
                      handleUpdateValue();
                      setEditTitle(false);
                    }}
                    type="button"
                    className="btnPrimary text-sm p-2 bg-green-600"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleUpdateKey("howToComponentTitle", howtoTitle);
                      setEditTitle(true);
                    }}
                    type="button"
                  >
                    <PencilIcon className="w-5 text-gray-500" />
                  </button>
                )}
              </div>
              {editTitle ? (
                <textarea
                  onChange={(e) => setUpdateValue(e.target.value)}
                  value={updateValue}
                  className="mt-1 inputField w-full"
                  required
                />
              ) : (
                <p className="elementHeading3">{howtoTitle}</p>
              )}
            </div>
            <div className="flex items-center gap-3 mt-3">
              <div className="flex items-center gap-2">
                {editDescription ? (
                  <button
                    onClick={() => {
                      handleUpdateValue();
                      setEditDescription(false);
                    }}
                    type="button"
                    className="btnPrimary text-sm p-2 bg-green-600"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleUpdateKey(
                        "howToComponentDescription",
                        howtoDescription
                      );
                      setEditDescription(true);
                    }}
                    type="button"
                  >
                    <PencilIcon className="w-5 text-gray-500" />
                  </button>
                )}
              </div>
              {editDescription ? (
                <textarea
                  onChange={(e) => setUpdateValue(e.target.value)}
                  value={updateValue}
                  className="mt-1 inputField w-full"
                  required
                />
              ) : (
                <p className="text-lg">{howtoDescription}</p>
              )}
            </div>
            <div className="min-h-fit mt-6 h-[calc(100vh-360px)] overflow-y-scroll">
              {howToSteps?.map((item, index) => (
                <div
                  key={index}
                  className="mb-3 grid gap-1 grid-cols-howTo text-left"
                >
                  <div className="flex items-center gap-2">
                    {editIndex === index ? (
                      <button
                        type="button"
                        className="btnPrimary text-sm p-2 bg-green-600"
                        onClick={() => handleSaveEditHowToSteps(index)}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btnPrimary text-sm p-2"
                        onClick={() => editHowToSteps(index)}
                      >
                        <PencilIcon className="w-4" />
                      </button>
                    )}
                    <button
                      type="button"
                      className="btnPrimary text-sm p-2 bg-red-500"
                      onClick={() => deleteHowToSteps(index)}
                    >
                      <TrashIcon className="w-4" />
                    </button>
                  </div>
                  <div className="p-1 flex items-center justify-center text-lg bg-white rounded-full text-gray-900 font-bold shadow-md shadow-black/25 w-10 h-10">
                    {index + 1}
                  </div>
                  {editIndex === index ? (
                    <textarea
                      className="mt-1 inputField"
                      value={editedValue}
                      onChange={(event) => setEditedValue(event.target.value)}
                      required
                    />
                  ) : (
                    <p className="mt-1">{item}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
