import React from "react";
import { Link } from "react-router-dom";

export default function LeftBar() {
  return (
    <div className="flex flex-col gap-2 sticky top-28">
      <Link href="/docs">Introduction</Link>
      <Link href="/docs">Project Structure</Link>
      <Link href="/docs">Routes</Link>
      <Link href="/docs">How to use</Link>
      <Link href="/docs">System Tags</Link>
      <Link href="/docs">Service page texts</Link>
      <Link href="/docs">Blogs</Link>
      <Link href="/docs">Create tag</Link>
      <Link href="/docs">Update tag</Link>
      <Link href="/docs">Amit apis</Link>
      <Link href="/docs">Vishnu apis</Link>
    </div>
  );
}
