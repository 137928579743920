import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import React from "react";
import InputField from "../../../components/common/InputField";

export default function ServicePageGuide() {
  return (
    <div>
      <h2 className="text-2xl font-semibold mt-20 pt-8 border-t">
        Services Page Text Guide
      </h2>
      <p className="font-bold mt-3 text-lg flex">
        Page Text 1 (Upper Text){" "}
        <span className="flex items-center gap-1 ml-3 text-blue-500 font-medium">
          Markdown <CheckBadgeIcon className="w-5 " />
        </span>
      </p>
      The tag used for first text of page is <b>servicename_text_1</b>. You can
      choose service text tag from the dropdown provided in the new tags popup.
      <InputField
        label="Enter Key Name"
        disabled
        value="chimney_sweep_text_1"
        className="mt-2"
      />
      <p>
        <p className="font-medium mt-2">Example:</p>
        If you need a text on the top of page against a service named chimney
        sweep. There should be no space between key words. Replace space witht
        under score. The tag will be chimney_sweep_text_1.
      </p>
      <p className="font-bold mt-3 text-lg flex items-center">
        Page Text 2 (Middle Text){" "}
        <span className="flex items-center gap-1 ml-3 text-blue-500 font-medium">
          Markdown <CheckBadgeIcon className="w-5 " />
        </span>
      </p>
      The tag used for text in the middle of page is <b>servicename_text_2</b>.
      <InputField
        label="Enter Key Name"
        disabled
        value="chimney_sweep_text_2"
        className="mt-2"
      />
      <p>
        <p className="font-medium mt-2">Example:</p>
        If you need a text in the middle of page against a service named chimney
        sweep. The tag will be chimney_sweep_text_2.
      </p>
    </div>
  );
}
