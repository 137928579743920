import React from "react";
import {
  ArrowRightOnRectangleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { onLogOut, setPassword } from "../../store/mySlice";

export default function UserBox() {
  const dispatch = useDispatch();
  const name = useSelector((state) => state.mySlice.name);
  return (
    <div className="navDropdownButton relative">
      <div className="navDropdown flex-col w-[200px] items-center absolute top-0 right-0 mt-11 p-2 bg-white dark:bg-blue rounded-md capitalize shadow-xl shadow-black/25">
        <button className="px-4 py-2 hover:rounded border-b text-left hover:bg-gray-100 w-full transition-all flex items-center justify-between">
          Profile
        </button>
        <button
          onClick={() => dispatch(onLogOut())}
          className="px-4 py-2 hover:rounded hover:bg-gray-100 dark:hover:bg-white/10 w-full transition-all flex items-center justify-between text-red-500"
        >
          Logout
          <ArrowRightOnRectangleIcon className="w-4" />
        </button>
      </div>
      <div className="flex items-center justify-end text-right cursor-pointer min-w-fit ml-5">
        <span className="hidden lg:block">
          <p className="font-semibold text-lg whitespace-nowrap capitalize">
            {name}
          </p>
          <p className="text-sm -mt-1 text-gray-400 lowercase">
            {name}@test.com
          </p>
        </span>
        <UserCircleIcon className="w-12 text-secondary" />
      </div>
    </div>
  );
}
