import React, { useEffect, useRef, useState, useMemo } from "react";
import { Bars3BottomLeftIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Company from "./Company";
import LayoutHead from "../../Layout/LayoutHead";
import { websites } from "../../../components/Menu/Menu";
import { Breadcrumbs } from "../../../components";

const Companies = ({ show_navlabel, toggleNavbar }) => {
  const { website, zip } = useParams();
  const site = websites.find((site) => site.domain === website);

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const topRef = useRef(null);

  useEffect(() => {
    const getCompanies = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${
            website?.includes("localhost") ? "http://" : "https://www."
          }${website}/api/zips?zip=${zip}`
        );
        setCompanies(data?.data || []);
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoading(false);
      }
    };

    getCompanies();
  }, [zip]);

  return (
    <div ref={topRef}>
      <LayoutHead>
        <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}
          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              href={`https://www.${site?.domain}`}
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
              rel="noreferrer"
            >
              <GlobeAltIcon className="text-gray-700 w-6 mt-1" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>

      <div className="py-5 px-7">
        <Breadcrumbs />
        <h2 className="elementHeading3 my-4 whitespace-nowrap">
          Companies in {zip}
        </h2>

        {loading ? (
          <p className="flex items-center justify-center p-44 text-lg">
            Loading Companies...
          </p>
        ) : (
          <div className="grid lg:grid-cols-1 2xl:grid-cols-2 gap-6">
            {companies?.length > 0
              ? companies.map((company) => (
                  <Company key={company.id} company={company} />
                ))
              : "No Zips Found"}
          </div>
        )}
      </div>
    </div>
  );
};

export default Companies;
