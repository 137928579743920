import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import React from "react";
import InputField from "../../../components/common/InputField";

export default function BlogsTags() {
  return (
    <div>
      <div>
        <h3 className="text-3xl font-semibold mt-20 pt-8 border-t">
          Blogs Tags And Text Guide
        </h3>
        <p className="text-gray-600 mt-3">
          Each blog on website have unique and seprate keys for title and
          description. For example If we have 3 blogs on the website. The tags
          will be
        </p>
        <ul className="list-disc pl-5 mt-2">
          <li>
            <div className="flex items-center gap-3">
              <p>blog_title_1</p> <p>blog_description_1</p>
            </div>
          </li>
          <li>
            <div className="flex items-center gap-3">
              <p>blog_title_2</p> <p>blog_description_2</p>
            </div>
          </li>
          <li>
            <div className="flex items-center gap-3">
              <p>blog_title_3</p> <p>blog_description_3</p>
            </div>
          </li>
        </ul>
        <div className="mt-3 flex items-center gap-2 text-lg">
          <p className="font-bold">Blog description tag example</p>
          <span className="flex items-center gap-1 ml-2 text-blue-500 font-medium">
            (Markdown) <CheckBadgeIcon className="w-5 " />
          </span>
        </div>
        <p>
          You can use markdown text in blog description. We are using markdown
          technology on frontend for blogs.
          <InputField
            label="Key Name"
            disabled
            value="blog_description_1"
            className="mt-3"
          />
          <label className="mb-1 mt-2 inputLabel">Key Value</label>
          <textarea
            disabled
            className="inputField h-48 text-sm"
            value="# Box Truck Towing
            Whether you're moving a load of furniture or need towing assistance, a box truck can be a handy tool. But what do you need to know before hitting the road with a box truck? Here's a quick guide to box truck towing.

            ## The Benefits of Box Truck Towing
            Box truck towing has become increasingly popular for hauling large items and debris, such as furniture and old appliances. By using box trucks for towing, you can reduce the cost of transporting bulky items compared to traditional tow trucks.
            "
          ></textarea>
        </p>
      </div>
      <div>
        <p className="font-bold mb-1 text-lg">Blog title tag example</p>
        <InputField label="Key Name" disabled value="blog_title_1" />
        <label className="mb-1 mt-2 inputLabel">Key Value</label>
        <textarea
          disabled
          className="inputField h-32"
          value={JSON.stringify({
            id: 1,
            title: "box truck towing",
            description:
              "Whether you're moving a load of furniture or need towing assistance, a box truck can be a handy tool. But what do you need to know before hitting the road with a box truck? Here's a quick guide to box truck towing.",
          })}
        ></textarea>
        <p className="text-lg font-medium mt-3 mb-1">Key Value Breakdown</p>
        <ul>
          <li>
            <b>id</b>: blog id
          </li>
          <li>
            <b>title:</b> enter blog title{" "}
            <span className="text-secondary">(must be in small letters)</span>
          </li>
          <li>
            <b>description:</b> short description about the blog
          </li>
        </ul>{" "}
        <p>
          <b>Note: </b>Each key or value will be in double quotes. Everything
          should must be in curly brackets {"{ }"}.
        </p>
      </div>
    </div>
  );
}
