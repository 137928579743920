import React, { useEffect, useRef, useState } from "react";
import LayoutHead from "../Layout/LayoutHead";
import {
  Bars3BottomLeftIcon,
  GlobeAltIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import { vishnuIds, websites } from "../../components/Menu/Menu";
import axios from "axios";
import Company from "./Company";
import { XMarkIcon } from "@heroicons/react/24/solid";

const Companies = ({ show_navlabel, toggleNavbar }) => {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);
  const secondId = vishnuIds.find((site) => site.domain === website);

  const [input, setInput] = useState("");
  const [searchError, setSearchError] = useState("");
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [service, setService] = useState("");

  useEffect(() => {
    axios
      .get(
        `https://${process.env.REACT_APP_API_ENDPOINT}/_${site.id}apidata/tagtext?tag=service_0&pass=billy`
      )
      .then((res) => setService(res.data.text))
      .catch((err) => console.error(err));
  }, [site.id]);

  // Search Function
  const searchMe = async () => {
    setLoading(true);
    setCompanies([]);
    if (input.length > 4) {
      setLoading(true);
      setSearchError("");
      try {
        const response = await axios.post(
          "https://api.cmsdeployer.com/api/custom_vendors/search_vendor",
          {
            zip: input,
            industry_id: secondId.id,
          }
        );
        if (response.data.length > 0) {
          setCompanies(response.data);
        } else {
          setSearchError("Services not available in this zip code.");
        }
      } catch (err) {
        setSearchError(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchError("Please enter at least 5 digits.");
      setLoading(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [companiesPerPage] = useState(7);

  const indexOfLastCompany = currentPage * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompanies = companies?.slice(
    indexOfFirstCompany,
    indexOfLastCompany
  );

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(companies?.length / companiesPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers?.map((number) => (
    <li
      key={number}
      onClick={() => setCurrentPage(number)}
      className={
        currentPage === number
          ? "active btnPrimary cursor-pointer"
          : "btnPrimary bg-transparent text-black cursor-pointer"
      }
    >
      {number}
    </li>
  ));

  const topRef = useRef(null);
  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNextClick = () => {
    const maxPage = Math.ceil(companies?.length / companiesPerPage);
    if (currentPage < maxPage) {
      setCurrentPage(currentPage + 1);
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <LayoutHead>
        <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}

          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              href={`https://www.${site?.domain}`}
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
              rel="noreferrer"
            >
              <GlobeAltIcon className="text-gray-700 w-6 mt-1" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>
      <div className="p-7">
        <div
          className={`flex items-center gap-10 mb-5 ${
            companies.length > 0
              ? "justify-between"
              : "w-full h-[70vh] justify-center"
          }`}
        >
          <div className="lg:max-w-2xl relative w-full p-2 z-10 backdrop-blur-sm lg:bg-white rounded-full lg:shadow">
            <div className="flex items-center justify-center gap-2">
              <div className="border lg:border-none flex items-center w-full appearance-none lg:px-3 rounded-full bg-white text-black py-2 flex-1">
                <MagnifyingGlassIcon className="w-5 text-primary hidden lg:block" />
                <input
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Zip code here"
                  onKeyPress={(e) => e.key === "Enter" && searchMe()}
                  type="number"
                  className="bg-transparent flex-1 w-full outline-none ml-5 lg:ml-3 py-1 lg:py-0 appearance-none"
                />
              </div>
              <button
                onClick={searchMe}
                className="btnPrimary rounded-full w-auto"
              >
                <span className="hidden lg:block">Search</span>
                <MagnifyingGlassIcon className="w-6 text-white lg:hidden" />
              </button>
            </div>
            <div className="absolute w-full flex items-center justify-center flex-col lg:items-start lg:px-16">
              {searchError && (
                <div className="bg-red-100 px-4 py-2 mt-5 text-lg rounded-md text-red-600 shadow-md w-full flex items-center justify-between gap-5">
                  {searchError}
                  <XMarkIcon
                    className="w-6 cursor-pointer"
                    onClick={() => setSearchError("")}
                  />
                </div>
              )}
              {loading && (
                <p className="bg-blue-50 text-primary px-4 py-2 mt-5 flex flex-col items-center rounded-md mb-1 text-lg font-medium text-center w-full">
                  Looking for desired area...
                  <img src="/loading.gif" className="w-12 mt-3" alt="" />
                </p>
              )}
            </div>
          </div>
          {companies.length > 0 && (
            <div className="hidden lg:flex items-center gap-5">
              <p className="text-gray-600 font-medium">
                page {currentPage} of {pageNumbers.length}
              </p>
              <ul className="flex items-center" id="page-numbers">
                <button
                  disabled={currentPage === 1}
                  className="mr-3 disabled:text-gray-400 disabled:cursor-not-allowed"
                  onClick={handlePrevClick}
                >
                  {"|< Previous "}
                </button>
                {renderPageNumbers}
                <button
                  disabled={currentPage === pageNumbers.length}
                  className="ml-3 disabled:text-gray-400 disabled:cursor-not-allowed"
                  onClick={handleNextClick}
                >
                  {"Next >| "}
                </button>
              </ul>
            </div>
          )}
        </div>
        {currentCompanies?.map((company, index) => (
          <Company
            key={index}
            service={service}
            companyName={company.name.replaceAll("##service##", service)}
            rating={company.rating}
            location={"Location"}
            hours={company.hours}
            state={company.state.toLowerCase()}
            county={company.county.toLowerCase()}
            city={company.city.toLowerCase()}
            zip={company.zip}
            phone={formatPhoneNumber(company.phone)}
          />
        ))}
        {companies.length > 0 && (
          <div className="flex items-center flex-col mt-3">
            <ul className="flex items-center" id="page-numbers">
              <button
                disabled={currentPage === 1}
                className="mr-3 disabled:text-gray-400 disabled:cursor-not-allowed"
                onClick={handlePrevClick}
              >
                {"|< Previous "}
              </button>
              {renderPageNumbers}
              <button
                disabled={currentPage === pageNumbers.length}
                className="ml-3 disabled:text-gray-400 disabled:cursor-not-allowed"
                onClick={handleNextClick}
              >
                {"Next >| "}
              </button>
            </ul>
            <p className="text-gray-600 font-medium mt-2">
              page {currentPage} of {pageNumbers.length}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Companies;
