import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setPassword, setName, onLogin } from "../../store/mySlice";
import { InputField } from "../../components";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { ToastContainer, toast } from "react-toastify";

export default function Login() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [input, setInput] = useState("");
  const handleSubmit = () => {
    dispatch(setName(username));
    dispatch(setPassword(input));
    if (username === "superadmin" && input === "inter@bit") {
      dispatch(onLogin(true));
    } else {
      toast.error("Invalid username or password");
    }
  };

  const [backgroundImageIndex, setBackgroundImageIndex] = useState(0); // Track the current background image index
  const imageLinks = [
    "url(https://images.unsplash.com/photo-1698766767195-fdaba9166ab9?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
    "url(https://images.unsplash.com/photo-1675006496754-e9181bdc3d51?auto=format&fit=crop&q=80&w=2069&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
    "url(https://images.unsplash.com/photo-1511576661531-b34d7da5d0bb?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
    "url(https://images.unsplash.com/photo-1682687218147-9806132dc697?auto=format&fit=crop&q=80&w=1975&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
    "url(https://plus.unsplash.com/premium_photo-1668024966086-bd66ba04262f?auto=format&fit=crop&q=80&w=2092&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
    "url(https://images.unsplash.com/photo-1655408780315-f9f5beb0af88?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
    "url(https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
    "url(https://images.unsplash.com/photo-1512453979798-5ea266f8880c?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
  ];
  const getRandomImageIndex = () => {
    return Math.floor(Math.random() * imageLinks.length);
  };
  const changeBackgroundImage = () => {
    const randomIndex = getRandomImageIndex();
    setBackgroundImageIndex(randomIndex);
  };

  return (
    <div
      style={{
        backgroundImage: imageLinks[backgroundImageIndex],
      }}
      className="flex items-center justify-center flex-col w-full min-h-screen bg-cover bg-center"
    >
      <ToastContainer />

      <button
        onClick={changeBackgroundImage}
        className="absolute top-5 right-5 btnPrimary text-base bg-black/40 rounded-full"
      >
        <PhotoIcon className="w-5" />
        Change Background
      </button>
      <form className="max-w-2xl md:w-8/12 lg:w-4/12 p-7 flex flex-col gap-3 rounded-lg bg-white shadow-2xl">
        <h1 className="font-bold text-xl text-center mb-1">
          Nationl Sites Admin Panel
        </h1>
        <InputField
          label="Name"
          type="username"
          placeholder="Enter username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <InputField
          label="Password"
          type="password"
          value={input}
          placeholder="Password"
          onChange={(e) => setInput(e.target.value)}
          required
        />
        <button
          type="button"
          onClick={handleSubmit}
          className="btnPrimary mt-1 text-base"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
