import React, { useState } from "react";
import { DocumentIcon, LinkIcon } from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import UserBox from "./UserBox";
import axios from "axios";

export default function LayoutHead({ children, site, loading }) {
  const [updatingSitemap, setUpdatingSitemap] = useState(false);

  const { website } = useParams();
  const handleUpdateSitemaps = (event) => {
    event.preventDefault();
    const confirmed = window.confirm(
      `This process will regenerate sitemap.  Please confrim to continue.`
    );
    if (!confirmed) return;
    setUpdatingSitemap(true);
    axios
      .get(
        `${
          website?.includes("localhost") ? "http://" : "https://www."
        }${website}/api/update_sitemap`,
        {
          headers: { Authorization: "Bearer AKIA3WTNB4VOJYIFVP7F" },
        }
      )
      .then((response) => {
        toast.success("Sitemap updated successfully");
      })
      .catch((error) => {
        toast.error("Sitemap couldn't be updated");
        console.error(error); // Handle errors as needed
      })
      .finally(setUpdatingSitemap(false));
  };
  return (
    <div className="sticky top-0 z-40 px-6 bg-white py-1 border-b">
      <ToastContainer />
      <div className="flex w-full items-center justify-between">
        {children}
        <div className="flex items-center justify-end gap-2">
          <Link to={`/${website}/zips`}>
            <button
              type="button"
              className="btnWhite rounded-md py-2 px-3 hover:bg-gray-100"
            >
              <DocumentIcon className="w-4" />
              Zip List
            </button>
          </Link>
          <Link to={`/docs`}>
            <button
              type="button"
              className="btnWhite rounded-md py-2 px-3 hover:bg-gray-100"
            >
              <DocumentIcon className="w-4" />
              Docs
            </button>
          </Link>
          <button
            // onClick={sitemap}
            onClick={handleUpdateSitemaps}
            type="button"
            disabled={updatingSitemap || loading}
            className={`btnWhite rounded-md py-2 px-3 ${
              updatingSitemap
                ? "bg-gray-300 cursor-not-allowed"
                : "transition transform hover:bg-gray-100"
            }`}
          >
            <LinkIcon
              className={`w-4 ${updatingSitemap ? " animate-bounce" : ""}`}
            />
            <p>Update Sitemap</p>
          </button>
          {/* <div className="relative">
            <button
              disabled={loading}
              type="button"
              // onClick={() => setDeployConfirmation(true)}
              onClick={handleDeployApi}
              className="btnPrimary bg-black text-sm font-medium disabled:cursor-not-allowed"
            >
              Deploy
            </button>
          </div> */}

          <UserBox />
        </div>
      </div>
    </div>
  );
}
