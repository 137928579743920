import React, { useState } from "react";
import { Link } from "react-router-dom";
import { websites } from "../../../components/Menu/Menu";

export default function Intro() {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredWebsites = websites.filter((item) =>
    item.domain.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h1 className="text-3xl font-semibold">Introduction</h1>
      <p className="mt-2 pb-10 mb-10 border-b text-gray-600">
        Welcome to the National Websites Content Dashboard documentation!
      </p>

      <h2 className="text-2xl font-semibold ">
        What is the National Sites Admin Panel?
      </h2>
      <p className="mt-2 text-gray-600">
        The{" "}
        <Link to="/dashboard" className="font-semibold hover:text-secondary">
          National Sites Admin Panel
        </Link>{" "}
        is a dashboard built to manage the content of 18 National Sites.
      </p>

      <p className="text-gray-600">
        Users can add, edit, and remove content from the website using the UI.
        The content includes blogs, FAQs, How-To sections, service pages texts,
        state page text, county page text, city page texts, and zip page texts.
        Other features of the dashboard include updating the sitemap, editing
        the robots.txt file, and syncing data from the MySQL database. Users can
        also redeploy the website from this UI.
      </p>

      <div className="flex items-center justify-between mt-7 w-full">
        <h2 className="text-xl font-semibold">List of National Sites</h2>
        <input
          type="text"
          placeholder="Search website..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-2 py-1 w-60 border rounded-md"
        />
      </div>

      <ul className="mt-2 text-gray-600">
        {filteredWebsites.map((item, index) => (
          <li key={index}>
            <a
              target="_blank"
              href={`https://www.${item?.domain}`}
              rel="noreferrer"
              className="hover:underline hover:text-secondary"
            >
              https://www.{item.domain}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
