import { DocumentIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import SearchBox from "../../components/common/SearchBox";
import UserBox from "../Layout/UserBox";

export default function Header() {
  return (
    <div className="sticky top-0 z-40 flex items-center border-b shadow-sm justify-between bg-white/60 backdrop-blur-sm py-2 px-4 lg:px-6 gap-4 lg:gap-7">
      <Link to="/" className="text-xl font-bold hidden md:block">
        National Sites Admin Panel
      </Link>
      <Link to={`/docs`}>
        <button type="button" className="btnWhite hover:bg-gray-100">
          <DocumentIcon className="w-4" />
          <p>Docs</p>
        </button>
      </Link>
      <SearchBox
        className="flex-1 bg-white"
        placeholder="Search in documentation"
      />
      <UserBox />
    </div>
  );
}
