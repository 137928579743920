import React from "react";
import LayoutHead from "../Layout/LayoutHead";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, SearchBox } from "../../components";
import { websites } from "../../components/Menu/Menu";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { PencilIcon } from "@heroicons/react/24/solid";

export default function Footer({ show_navlabel, toggleNavbar }) {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);
  const [footertag_1, setFooterTag1] = useState("");
  const [footertag_2, setFooterTag2] = useState("");
  const [footertag_3, setFooterTag3] = useState("");
  const [footertag_4, setFooterTag4] = useState("");
  const [footertext, setFooterText] = useState("");
  const [copyright, setCopyright] = useState("");
  const [quicklinks, setQuicklinks] = useState([]);

  const getFooterTag1 = () => {
    const tagName = "footertag_1";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setFooterTag1(res.data.text))
      .catch((err) => console.log(err));
  };
  const getFooterTag2 = () => {
    const tagName = "footertag_2";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setFooterTag2(res.data.text))
      .catch((err) => console.log(err));
  };
  const getFooterTag3 = () => {
    const tagName = "footertag_3";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setFooterTag3(res.data.text))
      .catch((err) => console.log(err));
  };
  const getFooterTag4 = () => {
    const tagName = "footertag_4";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setFooterTag4(res.data.text))
      .catch((err) => console.log(err));
  };
  const getFooterText = () => {
    const tagName = "footertext";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setFooterText(res.data.text))
      .catch((err) => console.log(err));
  };
  const getCopyright = () => {
    const tagName = "copyright";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setCopyright(res.data.text))
      .catch((err) => console.log(err));
  };
  const getQuicklinks = () => {
    const tagName = "quicklinks";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/taglist?pattern=${tagName}&pass=billy`
      )
      .then((res) => setQuicklinks(res.data.list))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getFooterTag1();
    getFooterTag2();
    getFooterTag3();
    getFooterTag4();
    getFooterText();
    getCopyright();
    getQuicklinks();
  }, []);

  return (
    <div>
      <LayoutHead>
        <div className="flex items-center gap-5">
          {!show_navlabel && (
            <>
              {" "}
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}
          <Breadcrumbs />
        </div>
      </LayoutHead>
      <div className="p-7">
        <div className="flex items-center justify-between">
          <h1 className="elementHeading2">Footer</h1>
          <div className="flex items-center justify-end gap-3">
            <SearchBox type="text" className="bg-white" placeholder="Search" />
            <button className="btnPrimary bg-green-600">Save & Update</button>
          </div>
        </div>
        <div className="bg-white rounded-lg p-10 flex flex-col items-center justify-center w-full mt-8 py-10">
          <div className="grid lg:grid-cols-3 gap-10 lg:gap-20 text-left">
            {/* Col 1 */}
            <div>
              <a
                title="Logo"
                href="/"
                className="flex items-center text-primary"
              >
                <img
                  title="logo"
                  src={`https://api15.ecommcube.com/${site.domain}/img/logo.webp`}
                  alt="logo"
                />
              </a>
              <p className="md:text-xl mt-2 font-semibold">{footertag_1}</p>
              <img
                title="start set"
                src={`https://api15.ecommcube.com/${site.domain}/img/star-set.webp`}
                className="mt-3"
                alt="logo"
              />
              <p className="mt-4">{footertext}</p>
            </div>

            {/* Col 2 */}
            <div className="flex-col flex text-center items-center">
              <p className="text-lg lg:text-2xl font-bold mb-5">
                {footertag_2}
              </p>
              <img
                title="bank cards"
                src={`https://api15.ecommcube.com/${site.domain}/img/bank-cards.webp`}
                alt="logo"
              />
              <p className="mt-10 text-left font-semibold text-xl text-gray-700">
                {footertag_3}
              </p>
              <div className="flex items-center gap-2 mt-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  title="Instagram"
                  href="https://www.instagram.com"
                  className="p-3 rounded-full bg-secondary"
                >
                  <img
                    className="w-6"
                    title="social-link"
                    src={`https://api15.ecommcube.com/${site.domain}/icons/ig.png`}
                    alt="instagram"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  title="Twitter"
                  href="https://www.twitter.com"
                  className="p-3 rounded-full bg-secondary"
                >
                  <img
                    className="w-6"
                    title="social-link"
                    src={`https://api15.ecommcube.com/${site.domain}/icons/tw.png`}
                    alt="twitter"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  title="Facebook"
                  href="https://www.facebook.com"
                  className="p-3 rounded-full bg-secondary"
                >
                  <img
                    className="w-6"
                    title="social-link"
                    src={`https://api15.ecommcube.com/${site.domain}/icons/fb.png`}
                    alt="facebook"
                  />
                </a>
              </div>
            </div>

            {/* Col 3 */}
            <div>
              <p className="text-2xl font-bold">{footertag_4}</p>
              <div className="grid gap-y-1 mt-2">
                {quicklinks?.map((item, index) => (
                  <a
                    key={index}
                    title={item}
                    target="_blank"
                    rel="noreferrer"
                    href={
                      item === "home"
                        ? `https://${site.domain}`
                        : `https://${site.domain}/${item.replaceAll(" ", "-")}`
                    }
                    className="hover:text-primary capitalize transition-all flex items-center gap-2"
                  >
                    {/* <PencilIcon className="w-3" /> */}
                    {item}
                  </a>
                ))}
                <Link
                  title="Sitemap"
                  target="_blank"
                  to={`https://www.${site.domain}/sitemap.xml`}
                  className="hover:text-primary capitalize transition-all"
                >
                  sitemap
                </Link>
              </div>
            </div>
          </div>
          <p className="text-center mt-12 text-gray-500">{copyright}</p>
        </div>
      </div>
    </div>
  );
}
