import React, { useEffect, useState } from "react";
import LayoutHead from "../Layout/LayoutHead";
import {
  ArrowPathIcon,
  Bars3BottomLeftIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import { vishnuIds, websites } from "../../components/Menu/Menu";
import axios from "axios";
import { DataTable } from "../../components";
import { TrashIcon } from "@heroicons/react/24/solid";
import { ToastContainer, toast } from "react-toastify";

const Quotes = ({ show_navlabel, toggleNavbar }) => {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);
  const secondId = vishnuIds.find((site) => site.domain === website);

  const [isLoading, setIsLoading] = useState(false);
  const [quoteList, setQuoteList] = useState([]);

  const tag_name = "quote_list";

  const getQuotesList = () => {
    setIsLoading(true);
    axios
      .get(
        `https://${process.env.REACT_APP_API_ENDPOINT}/_${site.id}apidata/tagtext?tag=${tag_name}&pass=billy`
      )
      .then((res) => setQuoteList(JSON.parse(res.data.text)))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getQuotesList();
  }, []);

  const handleDeleteQuote = (indexToDelete) => {
    const updatedQuotes = quoteList.filter(
      (_, index) => index !== indexToDelete
    );

    axios
      .post(
        `https://api15.ecommcube.com/_${site.id}apidata/long_jtags_update`,
        {
          [tag_name]: JSON.stringify(updatedQuotes),
          pass: "billy",
        }
      )
      .then(() => {
        toast.success("Quote request removed.");
        setQuoteList(updatedQuotes);
      })
      .catch((err) => toast.error(err));
  };

  return (
    <div>
      <LayoutHead>
        <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}

          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              href={`https://www.${site?.domain}`}
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
              rel="noreferrer"
            >
              <GlobeAltIcon className="text-gray-700 w-6 mt-1" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>

      <ToastContainer />

      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mb-5">
          <div className="flex items-center">
            <h2 className="text-2xl font-bold">Quote Requests</h2>
            <p className="px-3 h-7 rounded-full text-base ml-2 bg-secondary text-white flex items-center justify-center">
              {quoteList?.length}
            </p>
          </div>
          <button
            onClick={getQuotesList}
            className="btnPrimary bg-black text-base text-white px-3 active:scale-105 transition-all"
          >
            <ArrowPathIcon className={`w-4 ${isLoading && "animate-spin"}`} />{" "}
            Refresh
          </button>
        </div>
        <DataTable
          tableHeight="h-[calc(100vh-220px)]"
          heads={[
            "Sr#",
            "Actions",
            "Name",
            "Email",
            "Phone",
            "Zip Code",
            "Message",
            "City",
            "Company",
            "Location",
          ]}
          items={quoteList?.map((item, index) => ({
            serailNumber: index + 1,
            actions: (
              <div className="flex justify-center gap-2 items-center">
                <button
                  onClick={() => handleDeleteQuote(index)}
                  className="btnPrimary py-1 bg-red-500"
                >
                  <TrashIcon className="h-5" />
                </button>
                {/* <button className="btnPrimary py-1">View</button> */}
              </div>
            ),
            name: item.name,
            email: item.email,
            phone: item.phone,
            zipCode: item.zipCode,
            message: item.message,
            city: item.city,
            companyName: item.companyName,
            location: item.location,
          }))}
        />
      </div>
    </div>
  );
};

export default Quotes;
