import React from "react";
import LayoutHead from "../Layout/LayoutHead";
import {
  Accordian,
  Breadcrumbs,
  InputField,
  SearchBox,
} from "../../components";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/solid";
import { websites } from "../../components/Menu/Menu";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { GlobeAltIcon, XCircleIcon } from "@heroicons/react/24/outline";

export default function FAQs({ show_navlabel, toggleNavbar }) {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);
  const [faqsList, setFaqsList] = useState([]);
  const [faqsTitle, setFaqsTitle] = useState("");
  const [faqsDescription, setFaqsDescription] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [newFaq, setNewFaq] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredFaqs = faqsList.filter(
    (item) =>
      item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getFaqs = () => {
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=faqs_list&pass=billy`
      )
      .then((res) => {
        const parsedData = JSON.parse(res.data.text);

        // Ensure parsedData is an array
        if (Array.isArray(parsedData)) {
          const faqOptions = parsedData.map((item) => ({
            question: item.question,
            answer: item.answer,
          }));

          setFaqsList(faqOptions);
        } else {
          console.log("Invalid data format received from the API.");
        }
      })
      .catch((err) => console.log(err));
  };

  const getFaqsTitle = () => {
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=faqComponentTitle&pass=billy`
      )
      .then((res) => setFaqsTitle(res.data.text))
      .catch((err) => console.log(err));
  };

  const getFaqsDescription = () => {
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=faqComponentDescription&pass=billy`
      )
      .then((res) => setFaqsDescription(res.data.text))
      .catch((err) => console.log(err));
  };

  const handleAddFAQ = () => {
    // Create a new FAQ object
    const newFAQ = {
      question: question,
      answer: answer,
    };

    // Add the new FAQ to the existing list of FAQs
    setFaqsList([...faqsList, newFAQ]);
    setQuestion("");
    setAnswer("");
  };

  useEffect(() => {
    getFaqs();
    getFaqsTitle();
    getFaqsDescription();
  }, []);

  const UpdateOnServer = async (event) => {
    event.preventDefault();

    try {
      await axios.post(
        `https://api15.ecommcube.com/_${site.id}apidata/jtags_update`,
        null,
        {
          params: {
            faqs_list: JSON.stringify(faqsList),
            pass: "billy",
          },
        }
      );

      toast.success("Faqs Updated");
      getFaqs();
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <div>
      <LayoutHead>
      <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              {" "}
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}

          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              href={`https://www.${site?.domain}`}
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
            >
              <GlobeAltIcon className="text-gray-700 w-6 mt-1" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>
      <div className="p-6">
        <div className="flex items-center justify-between">
          <SearchBox
            className="bg-white shadow-sm"
            placeholder="Search Faq"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <h1 className="elementHeading2">FAQs</h1>
          {newFaq ? (
            <div className="flex items-center justify-end gap-3">
              <button
                onClick={UpdateOnServer}
                className="btnPrimary bg-green-600"
              >
                Save & Update
              </button>
              <button
                onClick={() => setNewFaq(false)}
                className="btnPrimary bg-red-500"
              >
                Cancel
              </button>
            </div>
          ) : (
            <button onClick={() => setNewFaq(true)} className="btnPrimary">
              Add New Faq
            </button>
          )}
        </div>
        <div className="bg-white p-5 rounded-lg mt-6">
          {newFaq && (
            <div className="grid grid-cols-faqInput gap-5 items-end mb-5">
              <InputField
                label="New Question"
                placeholder="Enter new FAQ"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
              <InputField
                label="New Answer"
                placeholder="Enter new FAQ answer here"
                value={answer}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleAddFAQ();
                  }
                }}
                onChange={(e) => setAnswer(e.target.value)}
              />
              <div className="flex flex-col items-end gap-2">
                <button
                  type="button"
                  className="btnPrimary h-fit disabled:cursor-not-allowed"
                  disabled={answer === "" || question === ""}
                  onClick={handleAddFAQ}
                >
                  Add FAQ
                </button>
              </div>
            </div>
          )}
          <Accordian
            site={site}
            faqsList={filteredFaqs}
            setFaqsList={setFaqsList}
            faqsTitle={faqsTitle}
            faqsDescription={faqsDescription}
          />
        </div>
      </div>
    </div>
  );
}
